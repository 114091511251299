// hooks/useChatMessages.js
import { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from '../firebase';

export default function useChatMessages(chatId) {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (!chatId) return;

    const messagesRef = ref(database, `chats/${chatId}/messages`);
    const unsubscribe = onValue(messagesRef, (snapshot) => {
      const msgs = [];
      snapshot.forEach((snap) => {
        msgs.push({ id: snap.key, ...snap.val() });
      });
      setMessages(msgs);
    });

    return () => unsubscribe(); // Отписываемся при размонтировании
  }, [chatId]);

  return messages;
}
