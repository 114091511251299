import React, { useState, useEffect } from 'react';
import { auth, database, firestore } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { ref, onValue } from 'firebase/database';
// В начале файла Chat.js
import './Chat.css';

import ChatList from '../components/chat/ChatList';
import ChatWindow from '../components/chat/ChatWindow';

const Chat = () => {
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);

  const [user, setUser] = useState(null);
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    // Следим за авторизацией и загружаем userInfo
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const userDocRef = doc(firestore, 'users', currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setUserInfo(userDocSnap.data());
        }
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Загружаем список чатов из Realtime Database
    const chatsRef = ref(database, 'chats');
    const unsubscribe = onValue(chatsRef, (snapshot) => {
      const loadedChats = [];
      snapshot.forEach((snap) => {
        const chat = { id: snap.key, ...snap.val() };
        loadedChats.push(chat);
      });
      setChats(loadedChats);
    });
    return () => unsubscribe();
  }, []);

  // При клике на «Wchodzę do czatu»
  const handleSelectChat = (chatId) => {
    const chat = chats.find((c) => c.id === chatId);
    if (chat) {
      setSelectedChat(chat);
      // Можете добавить дополнительную логику (пометить сообщения как прочитанные и т.д.)
    }
  };

  // ------------------ Стили для layout ------------------
  // Контейнер на весь экран, без прокрутки
  const containerStyle = {
    display: 'flex',
    height: '100vh',     // Фиксированная высота на весь экран
    margin: 0,
    padding: 0,
    overflow: 'hidden',  // Запрещаем скролл для всей страницы
  };

  // Левая колонка (список чатов) — 30%, скролл только здесь
  const leftColStyle = {
    width: '30%',
    overflowY: 'auto',   // Вертикальный скролл только в списке
  };

  // Правая колонка (окно чата) — 70%, без скролла
  const rightColStyle = {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden', // чтобы не было видно никакого скролла справа
  };

  return (
    <div style={containerStyle} >
      {/* Левая колонка — список чатов */}
      <div style={leftColStyle} className="hide-scrollbar">
        <ChatList
          chats={chats}
          chatStatus="open" // при желании поменяйте на стейт, если фильтруете open/closed
          onSelectChat={handleSelectChat}
          
        />
      </div>

      {/* Правая колонка — окно выбранного чата (без прокрутки) */}
      <div style={rightColStyle}>
        {selectedChat ? (
          <ChatWindow
  selectedChat={selectedChat}
  user={user}
  userInfo={userInfo}
  database={database}    // <-- Передаём Realtime Database
  firestore={firestore}  // <-- И Firestore (если нужно уведомление)
/>
        ) : (
          <div style={{ padding: '20px' }}>
            <h2>Wybierz czat z listy</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chat;
