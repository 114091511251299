import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Input,
  Button,
  Upload,
  message as antdMessage,
} from 'antd';
import {
  UploadOutlined,
  CloseCircleOutlined,
  FileOutlined,
} from '@ant-design/icons';
import './chat.css';

// Firebase (модульный SDK)
import { ref as dbRef, push, update, set } from 'firebase/database';
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore';

// Кастомный хук для чтения сообщений из Realtime Database (должен возвращать msg.id)
import useChatMessages from '../../hooks/useChatMessages';

function truncateFileName(fileName, maxLength = 20) {
  if (fileName.length <= maxLength) return fileName;
  const extIndex = fileName.lastIndexOf('.');
  const extension = extIndex > -1 ? fileName.slice(extIndex) : '';
  const baseName = extIndex > -1 ? fileName.slice(0, extIndex) : fileName;
  return `${baseName.slice(0, maxLength - extension.length - 3)}...${extension}`;
}

function extractFileName(url) {
  try {
    const fullPath = decodeURIComponent(url.split('?')[0]);
    return fullPath.substring(fullPath.lastIndexOf('/') + 1);
  } catch {
    return 'File';
  }
}

function formatDateSeparator(date) {
  const now = new Date();
  const d = new Date(date);
  const isToday =
    d.getDate() === now.getDate() &&
    d.getMonth() === now.getMonth() &&
    d.getFullYear() === now.getFullYear();
  return isToday
    ? 'Dzisiaj'
    : d.toLocaleDateString('pl-PL', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      });
}

const ChatWindow = ({
  selectedChat,
  user,
  userInfo,
  firestore,
  database,
}) => {
  const [message, setMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImageUrl, setPreviewImageUrl] = useState(null);

  // Массив сообщений (каждое сообщение имеет msg.id)
  const messages = useChatMessages(selectedChat?.id);

  // Реф, чтобы прокрутить вниз
  const messagesEndRef = useRef(null);
  const storage = getStorage();

  // Стили (можно перенести в отдельный .css)
  const chatWindowStyle = {
    border: '1px solid #ddd',
    borderRadius: 8,
    boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  };
  const headerStyle = {
    borderBottom: '1px solid #ddd',
    padding: '16px',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    fontSize: '16px',
    fontWeight: 500,
    backgroundColor: '#fafafa',
    display: 'flex',
    justifyContent: 'space-between', // чтобы кнопка закрытия была справа
    alignItems: 'center',
  };
  const messagesStyle = {
    flex: 1,
    overflowY: 'auto',
    padding: '16px',
    backgroundColor: '#f9f9f9',
  };
  const inputContainerStyle = {
    borderTop: '1px solid #ddd',
    padding: '16px',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    backgroundColor: '#fafafa',
  };
  const getMessageContainerStyle = (isOwnMessage) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: isOwnMessage ? 'flex-end' : 'flex-start',
    marginBottom: '6px',
  });
  const getBubbleStyle = (isOwnMessage) => ({
    backgroundColor: isOwnMessage ? '#DCF8C6' : '#fff',
    color: '#000',
    padding: '8px 12px',
    borderRadius: '16px',
    maxWidth: '60%',
    wordWrap: 'break-word',
    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
  });
  const timeAndStatusStyle = {
    fontSize: '0.75rem',
    color: '#888',
    marginTop: '4px',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 6,
  };

  // Автопрокрутка, когда массив messages меняется
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // При входе/смене чата или обновлении сообщений
  // отмечаем непрочитанные сообщения пользователя (role='user') как прочитанные
  useEffect(() => {
    if (!selectedChat || !messages || !messages.length) return;
    
    const updatePaths = {};

    // 1. Непрочитанные сообщения
    messages.forEach((msg) => {
      if (!msg.isRead && msg.senderRole === 'user') {
        updatePaths[`chats/${selectedChat.id}/messages/${msg.id}/isRead`] = true;
        updatePaths[`chats/${selectedChat.id}/messages/${msg.id}/readAt`] = new Date().toISOString();
      }
    });

    // 2. lastMessage, если отправлено пользователем и не прочитано
    if (
      selectedChat.lastMessage?.senderRole === 'user' &&
      selectedChat.lastMessage?.isRead === false
    ) {
      updatePaths[`chats/${selectedChat.id}/lastMessage/isRead`] = true;
      updatePaths[`chats/${selectedChat.id}/lastMessage/readAt`] = new Date().toISOString();
    }

    if (Object.keys(updatePaths).length > 0) {
      update(dbRef(database), updatePaths);
    }
  }, [selectedChat, messages, database]);

  // Сортируем сообщения по времени
  const sortedMessages = useMemo(() => {
    if (!messages) return [];
    return [...messages].sort(
      (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
    );
  }, [messages]);

  // Форматирование сообщений (дата, имя отправителя и т.д.)
  const formattedMessages = useMemo(() => {
    if (!selectedChat) return null;

    let result = [];
    let lastDate = null;
    let lastSenderId = null;

    sortedMessages.forEach((msg, index) => {
      const isOwnMessage = msg.senderRole === 'admin';
      const senderName = isOwnMessage ? 'Ty' : msg.sender;

      // Разделитель по датам
      const msgDateObj = new Date(msg.timestamp);
      const msgDateString = msgDateObj.toDateString();
      if (msgDateString !== lastDate) {
        result.push(
          <div
            key={`date-${msg.timestamp}`}
            style={{
              textAlign: 'center',
              margin: '12px 0',
              color: '#888',
              fontWeight: 500,
            }}
          >
            {formatDateSeparator(msg.timestamp)}
          </div>
        );
        lastDate = msgDateString;
        lastSenderId = null;
      }

      // Показывать имя отправителя, только если менялся senderId и это не админ
      const showSenderName = (msg.senderId !== lastSenderId) && !isOwnMessage;

      result.push(
        <div
          key={`${msg.timestamp}-${index}`}
          style={getMessageContainerStyle(isOwnMessage)}
        >
          {showSenderName && (
            <div style={{ fontSize: '0.85rem', fontWeight: 500, marginBottom: '4px' }}>
              {senderName}
            </div>
          )}

          <div style={getBubbleStyle(isOwnMessage)}>
            {msg.text && <div>{msg.text}</div>}

            {/* Изображение */}
            {msg.fileUrl && msg.fileType?.startsWith('image/') && (
              <div style={{ marginTop: '0.5rem' }}>
                <img
                  src={msg.fileUrl}
                  alt="img"
                  style={{ maxWidth: '200px', borderRadius: 4, cursor: 'pointer' }}
                  onClick={() => antdMessage.info('Podgląd obrazu')}
                />
              </div>
            )}

            {/* Другие файлы */}
            {msg.fileUrl && msg.fileType && !msg.fileType.startsWith('image/') && (
              <div style={{ marginTop: '0.5rem' }}>
                <FileOutlined style={{ fontSize: 18, marginRight: 8 }} />
                <a href={msg.fileUrl} download>
                  {truncateFileName(extractFileName(msg.fileUrl))}
                </a>
              </div>
            )}

            <div style={timeAndStatusStyle}>
              <span>
                {new Date(msg.timestamp).toLocaleTimeString('pl-PL', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </span>
              {isOwnMessage && (
                <>
                  {msg.isRead ? (
                    <span style={{ color: '#52c41a' }}>✓✓</span>
                  ) : (
                    <span>✓</span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      );

      lastSenderId = msg.senderId;
    });

    return result;
  }, [sortedMessages, selectedChat]);

  // Обработка выбранного файла
  const onFileChangeLocal = (info) => {
    const file = info.file.originFileObj || info.file;
    if (!file) return;
    setSelectedFile(file);

    if (file.type.startsWith('image/')) {
      const previewUrl = URL.createObjectURL(file);
      setPreviewImageUrl(previewUrl);
    } else {
      setPreviewImageUrl(null);
    }
  };

  const removeSelectedImage = () => {
    setSelectedFile(null);
    setPreviewImageUrl(null);
  };

  // Отправка сообщения
  const handleSendMessage = async () => {
    if (!selectedChat || !user) return;
    if (!message.trim() && !selectedFile) return;

    try {
      const senderName = userInfo?.firstName
        ? `${userInfo.firstName} z zespołu wsparcia`
        : 'Administrator';
      const senderRole = 'admin';

      let fileUrl = null;
      let fileType = null;

      if (selectedFile) {
        const fileRef = storageRef(
          storage,
          `chatFiles/${selectedChat.id}/${Date.now()}_${selectedFile.name}`
        );
        await uploadBytes(fileRef, selectedFile);
        fileUrl = await getDownloadURL(fileRef);
        fileType = selectedFile.type;
      }

      const newMsg = {
        text: message,
        fileUrl: fileUrl || null,
        fileType: fileType || null,
        sender: senderName,
        senderId: user.uid,
        senderRole,
        timestamp: new Date().toISOString(),
        isRead: false,
        readAt: null,
      };

      // Запись нового сообщения
      await push(dbRef(database, `chats/${selectedChat.id}/messages`), newMsg);

      // Обновление lastMessage
      await set(dbRef(database, `chats/${selectedChat.id}/lastMessage`), {
        text: message,
        fileUrl: fileUrl || null,
        fileType: fileType || null,
        senderName,
        senderRole,
        timestamp: newMsg.timestamp,
        isRead: false,
      });

      // (Опционально) Уведомление в Firestore
      if (firestore) {
        const notification = {
          title: 'Nowa wiadomość w czacie',
          text: `Otrzymano nową wiadomość od ${senderName}: ${message}`,
          createdAt: new Date(),
          isRead: false,
        };
        await addDoc(
          collection(firestore, `users/${selectedChat.creatorId}/notifications`),
          notification
        );
      }

      // Сброс полей
      setMessage('');
      setSelectedFile(null);
      setPreviewImageUrl(null);
    } catch (err) {
      console.error('Ошибка при отправке wiadomości:', err);
      antdMessage.error('Nie udało się wysłać wiadomości');
    }
  };

  // Закрытие чата (изменение статуса + причина)
  const handleCloseChat = async () => {
    if (!selectedChat) return;

    // Запрашиваем причину у оператора (или можно сделать Ant Design Modal)
    const reason = window.prompt('Podaj powód zamknięcia czatu:', '');
    if (!reason || !reason.trim()) {
      // Если пользователь не ввёл причину, не закрываем чат
      return;
    }

    try {
      const senderName = userInfo?.firstName
        ? `${userInfo.firstName} z zespołu wsparcia`
        : 'Administrator';

      const updates = {};
      // Меняем статус на закрытый
      updates[`chats/${selectedChat.id}/status`] = 'closed';
      // Кто закрыл (ID и имя)
      updates[`chats/${selectedChat.id}/closedBy`] = user.uid;
      updates[`chats/${selectedChat.id}/closedName`] = senderName;
      // Когда (ISO)
      updates[`chats/${selectedChat.id}/closedAt`] = new Date().toISOString();
      // Причина
      updates[`chats/${selectedChat.id}/closeReason`] = reason;

      // Обновляем одним вызовом
      await update(dbRef(database), updates);

      antdMessage.success('Czat został zamknięty');
    } catch (err) {
      console.error('Błąd przy zamykaniu czatu:', err);
      antdMessage.error('Nie udało się zamknąć czatu');
    }
  };

  // Если чат закрыт, можно отключить поле ввода (пример)
  const isChatClosed = selectedChat?.status === 'closed';

  return (
    <div style={chatWindowStyle} >
      {/* Заголовок чата */}
      <div style={headerStyle}>
        <div>
          <h2 style={{ margin: 0, fontSize: '16px' }}>
            {selectedChat?.chatName}
          </h2>
          <div style={{ color: '#555', fontSize: '14px' }}>
            <strong>Rozmowa z:</strong>{' '}
            {selectedChat?.creatorName || 'Nieznany'}
          </div>
          <div style={{ color: '#999', fontSize: '12px' }}>
            <strong>ID:</strong> {selectedChat?.creatorId}
          </div>
        </div>

        {/* Кнопка "Закрыть чат" */}
        {selectedChat?.status !== 'closed' && (
          <Button danger onClick={handleCloseChat}>
            Zamknij czat
          </Button>
        )}
      </div>

      {/* Список сообщений */}
      <div style={messagesStyle} className="hide-scrollbar">
        {formattedMessages}

        <div ref={messagesEndRef} />
      </div>

      {/* Если чат закрыт — показываем уведомление и скрываем ввод */}
      {isChatClosed ? (
        <div style={{ padding: '16px', backgroundColor: '#fafafa' }}>
          <strong>Czat został zamknięty.</strong>
          <p>Powód: {selectedChat?.closeReason || '(brak powodu)'}</p>
          <p>Data: {selectedChat?.closedAt 
                     ? new Date(selectedChat.closedAt).toLocaleString('pl-PL') 
                     : ''
             }</p>
        </div>
      ) : (
        // Панель ввода (поле, кнопка, превью файла) - если чат *не* закрыт
        <div style={inputContainerStyle}>
          {previewImageUrl && selectedFile?.type?.startsWith('image/') && (
            <div style={{ marginBottom: '1rem' }}>
              <img
                src={previewImageUrl}
                alt="Preview"
                style={{ maxWidth: 100, marginRight: 10, borderRadius: 4 }}
              />
              <CloseCircleOutlined
                onClick={removeSelectedImage}
                style={{ fontSize: 24, color: 'red', cursor: 'pointer' }}
              />
            </div>
          )}
          {selectedFile && !selectedFile.type.startsWith('image/') && (
            <div style={{ marginBottom: '1rem' }}>
              <FileOutlined />
              <span style={{ marginLeft: 8 }}>
                {truncateFileName(selectedFile.name)}
              </span>
              <CloseCircleOutlined
                onClick={removeSelectedImage}
                style={{ fontSize: 24, color: 'red', cursor: 'pointer', marginLeft: 8 }}
              />
            </div>
          )}

          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <Input
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onPressEnter={handleSendMessage}
              placeholder="Napisz wiadomość..."
            />
            <Upload
              beforeUpload={() => false}
              onChange={onFileChangeLocal}
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />} />
            </Upload>
            <Button type="primary" onClick={handleSendMessage}>
              Wyślij
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatWindow;
