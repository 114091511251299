import React, { useState, useMemo } from 'react';
import { List, Card, Button, Badge, Select, Input } from 'antd';
import { EnterOutlined } from '@ant-design/icons';
import './chat.css';

const { Option } = Select;

/**
 * Утилита для обрезания строки
 */
function truncateText(text, maxLength = 20) {
  if (!text) return '';
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength - 3) + '...';
}

const ChatList = ({ chats, onSelectChat }) => {
  // Выбор статуса (все/открытые/закрытые)
  const [statusFilter, setStatusFilter] = useState('all');
  // Фильтр по прочитанным/непрочитанным — используем unreadCount
  const [readFilter, setReadFilter] = useState('all');
  // Поиск по имени/фамилии
  const [searchTerm, setSearchTerm] = useState('');

  /**
   * 1) Фильтруем список чатов:
   *    - По статусу (all/open/closed)
   *    - По прочитанности (all/read/unread) — на основе chat.unreadCount
   *    - По поиску в creatorName
   */
  const filteredChats = useMemo(() => {
    return chats.filter((chat) => {
      // 1. Фильтрация по статусу
      if (statusFilter !== 'all' && chat.status !== statusFilter) {
        return false;
      }

      // 2. Фильтрация по прочитанности
      //    Считаем чат "непрочитанным", если unreadCount > 0
      const isUnread = (chat.unreadCount || 0) > 0;
      if (readFilter === 'unread' && !isUnread) {
        return false;
      }
      if (readFilter === 'read' && isUnread) {
        return false;
      }

      // 3. Поиск по имени/фамилии (creatorName)
      //    Приводим к нижнему регистру для case-insensitive сравнения
      const creatorName = (chat.creatorName || '').toLowerCase();
      if (!creatorName.includes(searchTerm.toLowerCase())) {
        return false;
      }

      return true;
    });
  }, [chats, statusFilter, readFilter, searchTerm]);

  /**
   * 2) Сортируем отфильтрованные чаты:
   *    - Сначала чаты с непрочитанными сообщениями (unreadCount > 0)
   *    - Потом по дате последнего сообщения (самые новые сверху)
   */
  const sortedChats = useMemo(() => {
    return [...filteredChats].sort((a, b) => {
      const aUnread = a.unreadCount || 0;
      const bUnread = b.unreadCount || 0;
      // Сначала непрочитанные
      if (bUnread !== aUnread) {
        return bUnread - aUnread;
      }
      // Потом по дате (либо дата последнего сообщения, либо дата самого чата)
      const aTime = a.lastMessage?.timestamp || a.timestamp;
      const bTime = b.lastMessage?.timestamp || b.timestamp;
      return new Date(bTime) - new Date(aTime);
    });
  }, [filteredChats]);

  /**
   * Превью последнего сообщения
   */
  const renderLastMessage = (chat) => {
    if (chat.lastMessage?.timestamp) {
      const dateStr = new Date(chat.lastMessage.timestamp).toLocaleString('pl-PL');
      const textPreview = chat.lastMessage.text
        ? `"${chat.lastMessage.text.slice(0, 30)}..."`
        : '';
      return (
        <div style={{ marginTop: '4px', color: '#888' }}>
          Ostatnia wiadomość: {dateStr}
          {textPreview && <span> — {textPreview}</span>}
        </div>
      );
    }
    return (
      <div style={{ marginTop: '4px', color: '#888' }}>
        Brak ostatniej wiadomości
      </div>
    );
  };

  /**
   * Рендер (обрезанного) названия чата + индикатор НЕпрочитанности по старой логике
   */
  const renderChatTitle = (chat) => {
    // Старая логика для красной точки: 
    // lastMessage.isRead === false & lastMessage.senderRole === 'user'
    const isUnreadLastMessage =
      chat.lastMessage &&
      chat.lastMessage.isRead === false &&
      chat.lastMessage.senderRole === 'user';

    const truncatedName = truncateText(chat.chatName, 25);

    // «Точка», если есть непрочитанное последнее сообщение (по старой логике)
    const dotStyle = {
      display: 'inline-block',
      width: 8,
      height: 8,
      borderRadius: 4,
      backgroundColor: '#f5222d',
      marginRight: 6,
    };

    return (
      <span style={{ fontWeight: isUnreadLastMessage ? 'bold' : 'normal' }}>
        {isUnreadLastMessage && <span style={dotStyle} />}
        {truncatedName}
      </span>
    );
  };

  return (
    <div style={{ paddingRight: '16px' }}>
      {/* Фильтры и поиск */}
      <div style={{ marginBottom: '1rem', display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
        {/* Статус */}
        <Select
          style={{ width: 120 }}
          value={statusFilter}
          onChange={(value) => setStatusFilter(value)}
        >
          <Option value="all">Wszystkie</Option>
          <Option value="open">Otwarte</Option>
          <Option value="closed">Zamknięte</Option>
        </Select>

        {/* Прочитанные / непрочитанные (на основе unreadCount) */}


        {/* Поиск по имени/фамилии */}
        <Input
          style={{ width: 200 }}
          placeholder="Szukaj po imieniu..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <List
        itemLayout="vertical"
        dataSource={sortedChats}
        renderItem={(chat) => {
          // Подсветка карточки, если есть хоть одно непрочитанное (unreadCount > 0)
          const isUnread = (chat.unreadCount || 0) > 0;

          const cardStyle = {
            backgroundColor: isUnread ? '#fff2e8' : '#fff', 
            border: isUnread ? '1px solid #faad14' : '1px solid #f0f0f0',
          };

          return (
            <List.Item key={chat.id}>
              <Card
                style={cardStyle}
                title={
                  <Badge
                    count={chat.unreadCount || 0}
                    offset={[12, 5]}
                    style={{
                      backgroundColor: isUnread ? '#f5222d' : '#52c41a',
                    }}
                  >
                    {renderChatTitle(chat)}
                  </Badge>
                }
                extra={
                  <Button
                    type="primary"
                    icon={<EnterOutlined />}
                    onClick={() => onSelectChat(chat.id)}
                  />
                }
              >
                <div>Status: {chat.status}</div>
                <div>
                  Utworzono:{' '}
                  {chat.timestamp
                    ? new Date(chat.timestamp).toLocaleString('pl-PL')
                    : '---'}
                </div>
                <div>
                  Stworzone przez: {chat.creatorName || 'Nieznany użytkownik'}
                </div>
                {renderLastMessage(chat)}
              </Card>
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default ChatList;
